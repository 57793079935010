.contentStickyBox {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #111111;
}

.contentPopupScaling {
  max-height: 86vh;
  overflow: auto;
}
.contentOptionBar {
  display: flex;
  color: white;
  justify-content: space-between;
  padding-bottom: 10px;
}
.contentPageInterfacesScaling {
  display: flex;
  flex-direction: column;
}

.contentButtonEnd {
  caret-color: transparent;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  background-color: #242424;
  border: 1px solid #2b7fbe;
  cursor: pointer;
}

.contentButton {
  cursor: pointer;
  caret-color: transparent;
  color: #cccccc;
  background-color: #242424;
  padding: 2px 6px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #2b7fbe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentListBox {
  background-color: #212121;
}
.contentListTableScaling {
  max-height: 84vh;
  border: 1px solid #dddddd;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #212121;
}
.contentListTopbar {
  transition: all 300ms ease;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #212121;
}

.contentListItem {
  border-top: 1px solid #a5a5a5;
  display: flex;
}
.contentListItemEntry {
  color: white;
  font-size: 13px;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  line-break: anywhere;
}

.contentListItemEntryIcon {
  color: white;
  transform: scale(1.2);
  font-size: 12px;
  padding: 6px;
  cursor: pointer;
}

.contentListItemEntryIcon:hover {
  color: #3aa3f5;
}

.contentListTopbarItem {
  color: white;
  font-weight: bold;
  caret-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
}

.contentListSearchField {
  background-color: #111111;
  caret-color: #dddddd;
  color: white;
  border: none;
  border: 1px solid #dddddd;
  outline: none;
  margin: 5px 5px 5px 0px;
  padding: 2px 5px;
  border-radius: 5px;
  transition: all 300ms ease;
}

.contentListSearchField:active {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #2b7fbe;
}

.contentListSearchField:hover {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #3aa3f5;
}

.contentPopup {
  display: flex;
  color: white;
  justify-content: space-between;
  caret-color: transparent;
  flex: 2;
  overflow-y: hidden;
}

.contentPopUpBox {
  display: flex;
  width: 100%;
  background-color: #212121;
  overflow-y: hidden;
}

.contentPopupHeader {
  color: white;
  position: sticky;
  background-color: #212121;
  top: 0px;
  z-index: 99;
  font-size: 25px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  box-shadow: -2px 5px 5px 0px #212121;
}
.contentPopupForm {
  width: 100%;
  overflow-y: auto;
  background-color: #212121;
}

.contentInputField {
  background-color: #303030;
  outline: none;
  border: 1px solid #dddddd;
  color: white;
  caret-color: white;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 17px;
}

.contentInputField:active {
  border: 1px solid #3aa3f5;
}

.contentInputField:hover {
  border: 1px solid #2b7fbe;
}

.contentPopupFormGroups {
  display: flex;
  padding-bottom: 20px;
  font-weight: bold;
}

.invalidPopupBubble {
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.contentFilter {
  caret-color: transparent;
  padding-right: 2px;
}

.contentFilter:hover {
  cursor: pointer;
  color: #3aa3f5;
}
