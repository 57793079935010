/* .scriptOptionBar{
  position: sticky;
  top: 80px;
  z-index: 999;
  background-color: #111111;
  display: flex;
  color: white;
  justify-content: space-between;
  padding-bottom: 10px;
}

.scriptButtonEnd{
  caret-color: transparent;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  background-color: #242424;
  border: 1px solid #2b7fbe;
  cursor: pointer;
}


.scriptButton{
  cursor: pointer;
  caret-color: transparent;
  color: #cccccc;
  background-color: #242424;
  padding: 2px 8px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #2b7fbe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scriptPageInterfacesScaling{
  display: flex;
  flex-direction: column;
  transition: all 300ms ease;
}

.scriptListTableScaling{
  max-height: 84vh;
  border: 1px solid #dddddd;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #212121;
}

.scriptListTopbar{
  border-bottom: 1px solid #dddddd;
  display: flex;
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #212121;
}

.scriptListTopbarItem{
  color: white;
  font-weight: bold;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dddddd;
  padding: 2px;
}

.scriptListSearchField{
  background-color: #111111;
  caret-color: #dddddd;
  color: white;
  border: none;
  border-top: 1px solid #dddddd;
  outline: none;
  width: 100%;
}

.scriptListSearchField:active{
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border-top: 1px solid #2b7fbe;
}

.scriptListSearchField:hover{
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border-top: 1px solid #3aa3f5;
}

.scriptListBox{
  background-color: #212121;
}

.scriptListItem{
  border-top: 1px solid #a5a5a5;
  display: flex;
}

.scriptListItemEntry{
  color: white;
  font-size: 13px;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #a5a5a5;
  padding: 2px;
  line-break: anywhere;
}

.scriptListItemEntryIcon{
  color: white;
  transform: scale(1.5);
  font-size: 12px;
  padding: 6px;
  cursor: pointer;
}

.scriptListItemEntryIcon:hover{
  color: #3aa3f5;
}

.scriptPopup{
  display: flex;
  color: white;
  justify-content: space-between;
  padding-bottom: 10px;
  caret-color: transparent;
  flex: 2;
  overflow-y: hidden;
}

.scriptPopUpBox{
  display: flex;
  width: 100%;
  background-color: #212121;
  border: 1px solid #dddddd;
  overflow-y: hidden;
}

.scriptPopupForm{
  padding: 0px 5px 5px 5px;
  width: 100%;
  overflow-y: auto;
  background-color: #212121;
}

.scriptPopupHeader{
  color: white;
  position: sticky;
  background-color: #212121;
  top: 0px;
  z-index: 99;
  font-size: 25px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  box-shadow: -2px 5px 5px 0px #212121;
}

.scriptPopupFormGroups{
  display: flex;
  padding-bottom: 10px;
  font-weight: bold;
}

.scriptInputField{
  background-color: #303030;
  outline: none;
  border: 1px solid #dddddd;
  color: white;
  caret-color: white;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: bold;
}

.scriptInputField:active{
  border: 1px solid #3aa3f5;
}

.scriptInputField:hover{
  border: 1px solid #2b7fbe;
}
*/

.invalidPopupBubble {
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.scriptStickyBox {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #111111;
}

.scriptPopupScaling {
  max-height: 86vh;
  overflow: auto;
}
.scriptOptionBar {
  display: flex;
  color: white;
  justify-content: space-between;
  padding-bottom: 10px;
}
.scriptPageInterfacesScaling {
  display: flex;
  flex-direction: column;
}

.scriptButtonEnd {
  caret-color: transparent;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  background-color: #242424;
  border: 1px solid #2b7fbe;
  cursor: pointer;
}

.scriptButton {
  cursor: pointer;
  caret-color: transparent;
  color: #cccccc;
  background-color: #242424;
  padding: 2px 6px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #2b7fbe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scriptListBox {
  background-color: #212121;
}
.scriptListTableScaling {
  max-height: 84vh;
  border: 1px solid #dddddd;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #212121;
}
.scriptListTopbar {
  transition: all 300ms ease;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #212121;
}

.scriptListItem {
  border-top: 1px solid #a5a5a5;
  display: flex;
}
.scriptListItemEntry {
  color: white;
  font-size: 13px;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  line-break: anywhere;
}

.scriptListItemEntryIcon {
  color: white;
  transform: scale(1.2);
  font-size: 12px;
  padding: 6px;
  cursor: pointer;
}

.scriptListItemEntryIcon:hover {
  color: #3aa3f5;
}

.scriptListTopbarItem {
  color: white;
  font-weight: bold;
  caret-color: transparent;
  display: flex;
  justify-content: center;
  padding: 2px 6px;
}

.scriptListSearchField {
  background-color: #111111;
  caret-color: #dddddd;
  color: white;
  border: none;
  border: 1px solid #dddddd;
  outline: none;
  margin: 5px 5px 5px 0px;
  padding: 2px 5px;
  border-radius: 5px;
  transition: all 300ms ease;
}

.scriptListSearchField:active {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #2b7fbe;
}

.scriptListSearchField:hover {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #3aa3f5;
}

.scriptPopup {
  display: flex;
  color: white;
  justify-content: space-between;
  caret-color: transparent;
  flex: 2;
  overflow-y: hidden;
}

.scriptPopUpBox {
  display: flex;
  width: 100%;
  background-color: #212121;
  overflow-y: hidden;
}

.scriptPopupHeader {
  color: white;
  position: sticky;
  background-color: #212121;
  top: 0px;
  z-index: 99;
  font-size: 25px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  box-shadow: -2px 5px 5px 0px #212121;
}
.scriptPopupForm {
  width: 100%;
  overflow-y: auto;
  background-color: #212121;
}

.scriptInputField {
  background-color: #303030;
  outline: none;
  border: 1px solid #dddddd;
  color: white;
  caret-color: white;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 17px;
}

.scriptInputField:active {
  border: 1px solid #3aa3f5;
}

.scriptInputField:hover {
  border: 1px solid #2b7fbe;
}

.scriptPopupFormGroups {
  display: flex;
  padding-bottom: 10px;
  font-weight: bold;
}

.invalidPopupBubble {
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.scriptFilter {
  caret-color: transparent;
  padding-right: 2px;
}

.scriptFilter:hover {
  cursor: pointer;
  color: #3aa3f5;
}
