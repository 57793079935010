.topbarBox {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 25px 15px;
  caret-color: transparent;
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #111111;
}

.googleLoginButton {
  width: 32px;
  height: 32px;
  caret-color: transparent;
}

.sidebarToggleBars {
  color: white;
  scale: 1.2;
  caret-color: transparent;
  padding-top: 3px;
}

.sidebarToggleBars:hover {
  cursor: pointer;
  color: #3aa3f5;
}

.googleLogo {
  object-fit: contain;
  max-height: 32px;
  border-radius: 16px;
  outline: 2px solid #3aa3f5;
}

.googleLogo:hover {
  outline: 2px solid white;
  cursor: pointer;
}

.googleLogoutButton {
  height: 25px;
  width: 72px;
  border-radius: 2px;
  outline: 1px solid #3aa3f5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #181818;
  cursor: pointer;
  caret-color: transparent;
}

.googleLogoutButton:hover {
  outline: 1px solid white;
}

.googleLogoutText {
  padding: 0px 3px;
  cursor: pointer;
}
