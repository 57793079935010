.scheduleStickyBox {
  position: sticky;
  top: 0px;
  z-index: 999;
  background-color: #111111;
}

.schedulePopupScaling {
  max-height: 86vh;
  overflow: auto;
}
.scheduleOptionBar {
  display: flex;
  color: white;
  justify-content: space-between;
  padding-bottom: 10px;
}
.schedulePageInterfacesScaling {
  display: flex;
  flex-direction: column;
}

.scheduleButtonEnd {
  caret-color: transparent;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  background-color: #242424;
  border: 1px solid #2b7fbe;
  cursor: pointer;
}

.scheduleButton {
  cursor: pointer;
  caret-color: transparent;
  color: #cccccc;
  background-color: #242424;
  padding: 2px 6px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #2b7fbe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduleListBox {
  background-color: #212121;
}
.scheduleListTableScaling {
  max-height: 84vh;
  border: 1px solid #dddddd;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #212121;
}
.scheduleListTopbar {
  transition: all 300ms ease;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #212121;
}

.scheduleListItem {
  border-top: 1px solid #a5a5a5;
  display: flex;
}
.scheduleListItemEntry {
  color: white;
  font-size: 13px;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  line-break: anywhere;
}

.scheduleListItemEntryIcon {
  color: white;
  transform: scale(1.2);
  font-size: 12px;
  padding: 6px;
  cursor: pointer;
}

.scheduleListItemEntryIcon:hover {
  color: #3aa3f5;
}

.scheduleListTopbarItem {
  color: white;
  font-weight: bold;
  caret-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
}

.scheduleListSearchField {
  background-color: #111111;
  caret-color: #dddddd;
  color: white;
  border: none;
  border: 1px solid #dddddd;
  outline: none;
  margin: 5px 5px 5px 0px;
  padding: 2px 5px;
  border-radius: 5px;
  transition: all 300ms ease;
}

.scheduleListSearchField:active {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #2b7fbe;
}

.scheduleListSearchField:hover {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #3aa3f5;
}

.schedulePopup {
  display: flex;
  color: white;
  justify-content: space-between;
  caret-color: transparent;
  flex: 2;
  overflow-y: hidden;
}

.schedulePopUpBox {
  display: flex;
  width: 100%;
  background-color: #212121;
  overflow-y: hidden;
}

.schedulePopupHeader {
  color: white;
  position: sticky;
  background-color: #212121;
  top: 0px;
  z-index: 99;
  font-size: 25px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  box-shadow: -2px 5px 5px 0px #212121;
}
.schedulePopupForm {
  width: 100%;
  overflow-y: auto;
  background-color: #212121;
}

.scheduleInputField {
  background-color: #303030;
  outline: none;
  border: 1px solid #dddddd;
  color: white;
  caret-color: white;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 17px;
}

.scheduleInputField:active {
  border: 1px solid #3aa3f5;
}

.scheduleInputField:hover {
  border: 1px solid #2b7fbe;
}

.schedulePopupFormGroups {
  display: flex;
  padding-bottom: 10px;
  font-weight: bold;
}

.invalidPopupBubble {
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: normal;
}

.scheduleFilter {
  caret-color: transparent;
  padding-right: 2px;
}

.scheduleFilter:hover {
  cursor: pointer;
  color: #3aa3f5;
}
