.pageBody {
  flex: 1;
  padding: 0px 10px;
  margin-bottom: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #464646;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
