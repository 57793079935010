.pageLayoutBoxes {
  display: flex;
  height: 90vh;
  flex-direction: column;
}

.clientGroupSelection {
  flex: 2;
  caret-color: transparent;
  background-color: #181818;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  overflow-x: auto;
  overflow-y: auto;
}

.clientBox {
  flex: 5;
  display: flex;
  overflow: hidden;
}

.clientSelection {
  flex: 5;
  background-color: #141414;
  border: 1px solid #dddddd;
  border-top: none;
  overflow: hidden;
}

.clientInformation {
  flex: 3;
  background-color: #212121;
  border: 1px solid #dddddd;
  overflow-x: hidden;
  overflow-y: auto;
}

.clientGroupSelectionItem {
  color: #eeeeee;
  font-weight: bold;
  cursor: pointer;
  margin: 5px;
  margin-left: 15px;
  margin-right: 0px;
  transition: all 300ms ease;
}

.clientGroupSelectionItem:hover {
  color: #dddddd;
}

.clientSelectionFilter {
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: #1b1b1b;
}

.clientListSearchField {
  background-color: #111111;
  caret-color: #dddddd;
  color: white;
  border: none;
  border: 1px solid #dddddd;
  outline: none;
  margin: 5px 5px 5px 0px;
  padding: 2px 5px;
  border-radius: 5px;
  max-width: 160px;
  transition: all 300ms ease;
}

.clientNoteField {
  background-color: #111111;
  caret-color: #dddddd;
  color: white;
  border: none;
  border: 1px solid #dddddd;
  outline: none;
  margin: 5px 5px 5px 0px;
  padding: 2px 5px;
  border-radius: 5px;
  transition: all 300ms ease;
}

.clientListSearchField:active {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #2b7fbe;
}

.clientScheduleBox {
  color: #dddddd;
  font-size: 13px;
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  line-break: anywhere;
  border-bottom: 1px solid #2b7fbe;
}

.clientListSearchField:hover {
  background-color: #1b1b1b;
  outline: none;
  border: none;
  border: 1px solid #3aa3f5;
}

.clientSelectionOption {
  color: #d3d3d3;
  caret-color: transparent;
  border-bottom: 1px solid #555555;
  padding: 5px 5px;
  margin: 0px 5px;
  cursor: pointer;
  transition: all 300ms ease;
}
.clientSelectionOption:hover {
  background-color: #212121;
  border-bottom: 1px solid #999999;
}

.clientSelectionScrollable {
  overflow-y: auto;
  height: calc(100% - 32px);
}

.clientSelectionItemHeader {
  padding-right: 5px;
  font-weight: bolder;
  color: #3aa3f5;
}

.clientSelectionItem {
  display: flex;
  font-size: 12px;
}

.clientSelectionItemSpacing {
  margin-bottom: 3px;
}

.clientSelectionItemName {
  border-bottom: 1px solid #777777;
  padding-left: 5px;
  margin-bottom: 5px;
}

.clientSelectionIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #161616;
  color: #eeeeee;
  border-radius: 5px;
  border: 1px solid #acacac;
  margin-top: 5px;
  transition: all 300ms ease;
}

.clientSelectionIcon:hover {
  color: #dddddd;
  cursor: pointer;
  background-color: #212121;
  border-color: #dddddd;
}

.clientSelectionTabs {
  width: 40px;
  border-right: 1px solid #eeeeee;
  background-color: #161616;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clientLogBox {
  margin: 5px;
  margin-bottom: 0px;
  padding: 0px 5px;
  height: calc(100% - 6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.clientInfoImageOutline {
  width: 160px;
  height: 90px;
  border: 1px solid #2b7fbe;
  display: flex;
  justify-content: center;
  align-items: center;
}
